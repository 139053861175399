
// export { isEmpty, isUndefined, isEqual, isDate, isNil, isNaN } from './dash';
// Centralizes all the lodash we use so we can easily replace with native where appropriate
// https://www.blazemeter.com/blog/the-correct-way-to-import-lodash-libraries-a-benchmark

// import { isEqual } from './objectz/isEqual'
// import { isEmpty } from './objectz/isEmpty'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import isMatchWith from 'lodash/isMatchWith';
import isMatch from 'lodash/isMatch';

export {
  isMatchWith, isMatch, isEmpty, isEqual
}

export function isObject(value) {
  var type = typeof value;
  return value != null && (type == 'object' || type == 'function');
}

export const isUndefined = val => val === undefined;

export const isDate = val => val.constructor.name == 'Date';

export function isNil(value) {
  return value == null
}

export function isString(value) {
  return  (typeof value === 'string' || value instanceof String)
}

export function isPlainObject(o) {
  if (isObject(o) === false) return false;

  // If has modified constructor
  let ctor = o.constructor;
  if (ctor === undefined) return true;

  // If has modified prototype
  let prot = ctor.prototype;
  if (isObject(prot) === false) return false;

  // If constructor does not have an Object-specific method
  // eslint-disable-next-line no-prototype-builtins
  if (prot.hasOwnProperty('isPrototypeOf') === false) return false;

  return true;
}

/**
 * Checks if `value` is an empty object or collection.
 * Objects are considered empty if they have no own enumerable string keyed properties.
 * Arrays are considered empty if they have a `length` of `0`.
 */

export function isFunction(value) {
  return value && (Object.prototype.toString.call(value) === "[object Function]" || "function" === typeof value || value instanceof Function);
}

/**
 * isNothing , a bit like isEmpty, is useful for things like formatters
 * where you want to check if the value has something to operates on.
 * Also used when 'pruning' an object to remove all keys that are not 'something'
 *
 * The following returns true
 *  - NaN, null and undefined of course return true
 *  - objects, strings and arrays if empty/zero length
 *  - date objects always return true
 *
 */
 export function isNothing(value) {
  return  Number.isNaN(value) ||
    isNil(value) ||
    (isString(value) && isEmpty(value)) ||
    (isObject(value) && !isDate(value) && isEmpty(value))
}

/**
 * see isNothing. Returns true if val is something.
 * Basically isTruthy but without the check for true
 */
export function isSomething(val) {
  return !isNothing(val)
}

/**
 * isFalsy is isNothing with checks for false and 0
 * The following returns true
 *  - NaN, null and undefined of course return true
 *  - empty objects, strings and arrays, length=0
 *
 * date objects always return false
 *
 * @param value any value
 * @returns boolean false or true
 */
export function isFalsy(value) {
  return value === false || value === 0 || isNothing(value)
}

/**
 * isFalsy but if its a String will also returns false if 'false|False|FALSE'
 */
export function falsyCheck(value) {
  return value === false || value === 0 ||
    (isString(value) && value.toLowerCase() === 'false') ||
    isNothing(value)
}

export function isTruthy(value) {
  return !isFalsy(value)
}

/**
 * inverted falsyCheck but if its a String will also returns true if is NOT 'false|False|FALSE'
 */
export function truthyCheck(value){
  return !falsyCheck(value)
}

// export * from './truthy'
