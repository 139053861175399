import Logger from '../logger'; let log = Logger(false);
import { get, writable, derived } from 'svelte/store';
import { getService } from '../injector'
import { defaults, _get, extend} from '../objectz';
import { isEmpty, isEqual } from '../utils/is';
import {createConfigStore} from './configStore'
import { modelStore } from './ModelStore'
import type { DataApi } from '../api';
import type { QueryStore, Page } from './types';
// import type { Page, QueryStore } from './types';

/**
 * Makes a QueryStore using parameters in the spirit of ActiveRecord and angularJs old $resource service.
 * Default is paged view.
 * Used to back any editable grid or list and has stores for
 *  - currentPage
 *  - activeModel
 *  - selectedIds
 *
 * @param {object} p
 * @param p.key - the apiKey to lookup in dataApiFactory
 * @param p.dataApi - that rest data api, either this or key need to be specified
 * @param p.opts - the options to pass in for key etc.
 * @returns the query store
 */
export function makeQueryStore(
    { key = undefined, dataApi = undefined, opts = {} }: { key?:string, dataApi?: DataApi, opts?: any }
  ): QueryStore {

  if(key) dataApi = getService('dataApiFactory').get(key) as DataApi
  log("dataApi", dataApi)

  const testDelay = 0 //for testing DO NOT CHECK IN WITH A VALUE
  // const testDelay = 1000

  let apiPath = dataApi.path
  let apiKey = dataApi.key
  let configStore = createConfigStore({dataApi})

  defaults(opts, {
    page: 1, max: 20
  })

  const stateValues = {
    /** if this is init and has retrieved its config*/
    isReady: false,
    dense: false,
    showSearchForm: false,
    canEdit: true,
    isLoading: true
  }

  let currentPage = writable({} as Page)
  let activeModel = modelStore({ dataApi })
  //it seems stores clear data on last subscription
  // currentPage.subscribe(data => {
  //   console.log("crudQueryStores pageViewStore sub", data)
  // });

  const restrictSearch = writable({})
  const q = writable({})
  const sort = writable({})
  const qSearch = writable('')
  const page = writable(opts.page)
  const max = writable(opts.max)

  const selectedIdValues = new Set()
  const selectedIds = writable(selectedIdValues)

  const searchParams = derived([restrictSearch, q, qSearch, sort, page, max],
                                ([$restrictSearch, $q, $qSearch, $sort, $page, $max]) => {
    let svals = {q: $q, qSearch: $qSearch, sort:$sort, page: $page, max: $max,}
    //merge restrictSearch in if it there
    //in case when we are getting dataApi from resource we miss restriction for it
    dataApi.restrictSearch = $restrictSearch
    if(!isEmpty($restrictSearch)) {
      svals.q = {...svals.q, ...$restrictSearch}


    }
    return svals;
  });

  //the store sub that is returned
  const state = writable(stateValues)

  const obj = {
    unsubs: [],
    state,
    currentPage,
    activeModel,
    configStore,
    restrictSearch,
    q,
    qSearch,
    page,
    max,
    dataApi,
    sort,
    selectedIds,
    apiPath,
    apiKey
  } as Partial<QueryStore>

  function updateFromParams(p) {
    ['q', 'sort', 'qSearch', 'page', 'max'].forEach(k => {
      if(p[k] !== undefined) obj[k].set(p[k])
    })
  }

  function setLoading(val){
    state.update(_state => {
      _state.isLoading = val
      return _state
    })
  }

  function setReady(val){
    state.update(_state => {
      _state.isReady = val
      return _state
    })
  }
  //only updates if they are different, first converts to number array to be consistent
  function setSelected(newVals){
    let numberArray = newVals.map(el=>parseInt(el))
    let curSetVals = get(selectedIds)
    let curVals = [...curSetVals]
    if(!isEqual(curVals, numberArray)){
      // log("not equal to setting", curVals, numberArray)
      selectedIds.set(new Set(numberArray))
    }
  }

  return extend(obj, {
    //subscribe and set implement store contract so return can be used as store to check state $resource.isReady for example
    subscribe: state.subscribe,
    set: state.set,
    update: state.update,
    setSelected,
    setLoading,
    getConfigs: configStore.getConfigs,
    loadConfigs: configStore.loadConfigs,
    loadMeta: configStore.loadMeta,
    getMeta: configStore.getMeta,

    /**
     * if isReady=false then loads configs.
     */
    async init() {
      if(stateValues.isReady) return
      await configStore.init()
      setReady(true)
      return obj
    },

    /** helper for testing */
    delay(ms){
      ms = ms || testDelay

      return new Promise(resolve => setTimeout(resolve, ms))
    },
    /** returns an id from the apiKey */
    ident(){
      return apiPath.replace('/', '_')
    },

    /** loads the active item from the server */
    async loadActive(_id){
      setLoading(true)
      log("--------- activeModel.load", _id)
      const item = await activeModel.load(_id)
      setLoading(false)
      return item
    },

    /** sets the activeModel from the cached data in the currentPage */
    setActiveFromPageData(_id) {
      if(!_id) throw new Error("setActiveFromPageData called without an id")
      //make sure its an int
      const id = parseInt(_id)
      if(id === activeModel.getId()) return //if its the same then just return

      const currentPageVal = get(currentPage)
      const pageData = currentPageVal['data']
      const dataItem = dataApi.findById(pageData, id)
      log("setActiveFromPageData", apiKey, dataItem )
      if(dataItem == false) throw new Error("setActiveFromPageData failed findById for id: " + id)
      activeModel.set(dataItem)
    },

    /**
     * adds item to currentPage store and updates its total and records counts
     */
    addCurrentPageItem(item){
      log("addCurrentPageItem")
      currentPage.update(page => {
        page.data.unshift(item) //add to beggining
        if(page.records) page.records = page.records + 1
        return page
      })
    },

    /**
     * adds item to currentPage store and updates its total and records counts
     */
    updateCurrentPageItem(item){
      currentPage.update(page => {
        let data = page.data
        const idx = dataApi.findIndexById(data, item[dataApi.idProp])
        data[idx] = item
        return page
      })
    },

    async saveAndSync(_data){
      log("saveAndSync with data", _data )
      const id = _data[dataApi.idProp]
      let savedItem = await activeModel.upsert(_data)
      //if we are on a single page we dont have data in page and dont need to update it
      if (!get(currentPage).data) return savedItem
      if(id){
        obj.updateCurrentPageItem(savedItem)
      } else {
        obj.addCurrentPageItem(savedItem)
      }
      return savedItem
    },

    async query(p) {
      //for search we need to set page to 1, but for cases when we using pagination we need to set page
      const params = {page: 1, ...p}
      setLoading(true)
      updateFromParams(params)
      const $searchParams = get(searchParams)
      // log("query", apiKey, $searchParams)
      const page = await dataApi.search($searchParams)
      currentPage.set(page)
      setLoading(false)
      return page
    },

     async download(p) {
      setLoading(true)
      if(p) updateFromParams(p)
      const $searchParams = get(searchParams)
      // log("query", apiKey, $searchParams)
      const blob = await dataApi.download($searchParams)
      setLoading(false)
      return blob
    },

    /**
     * an alias to list that can be overriden.
     */
    reload() {
      // selectedIds.set([])
      //on reload we should stay on the same page 
      //and due to query is by default goes to the first page need to pass current page
      return obj.query({page: get(currentPage).page})
    },

    async delete(id) {
      let res = await dataApi.remove(id)
      // currentPage.update( _page => {
      //   const pageData = _page['data']
      //   return pageData.filter(it => it.id != id)
      // })
      selectedIds.update( ids => {
        return new Set([...ids].filter(it => it != id))
      })
      obj.reload()
      return res
    },
    /**
     * update to dataApi and calls setCurrent so current store is updated.
     */
    // async update(values) {
    //   if(!values.id) throw new Error("update called without id")
    //   const updatedItem = await dataApi.update(values)
    //   obj.setCurrent(updatedItem)
    //   return updatedItem
    // },
    //normally use the $resource.isReady but if mixing custom resource can use this as a shortcut check
    isReady(){
      return stateValues.isReady
    },
    //use when mixing custom resources
    setReady(){
      setReady(true)
      state.update(_state => ({..._state, isReady:true}))
    },
    /**
     * clears the page store
     */
    clearCurrentPage() {
      currentPage.set({})
    },

    /**
     * clears the activeModel store
     */
    clearActiveModel() {
      activeModel.set({})
    },
    /**
     * clears the activeModel store
     */
    clearSelected() {
      selectedIds.set(new Set())
    },

    clearAll(){
      obj.clearSelected()
      obj.clearActiveModel()
      obj.clearCurrentPage()
    },

    toggleSort(sortProp){
      sort.update(sortObj => {
        let sortOrder = 'asc'
        if (sortObj[sortProp] === 'asc') {
          sortObj = {[sortProp]: 'desc'}
        } else if(sortObj[sortProp] === 'desc'){
          //toggle off if desc
          sortObj = {}
        } else {
          sortObj = {[sortProp]: 'asc'}
        }
        return sortObj
      })
      obj.query()
    },

    resetSort(){
      sort.set({})
      log("resetSort", get(sort))
      obj.query()
    },

    updateSort(sortProp, sortOrder = 'none'){
      sort.update(sortObj => {
        if (sortOrder === 'none') {
          sortObj = {}
        } else {
          sortObj = {[sortProp]: sortOrder}
        }
        return sortObj
      })
      obj.query()
    }
  }) //end mix
}

