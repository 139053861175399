import Logger from '@yakit/core/logger'; let log = Logger(false);
import PaymentQuery from './PaymentQuery'
import { get, writable } from 'svelte/store';
import { makeQueryStore } from '@yakit/core/resource'
import { isEmpty } from '@yakit/core/is'
import { extend } from '@yakit/core/objectz';


export function BatchQuery(){

  const batchQuery = makeQueryStore({ key: 'autocash.batch' })
  const paymentQuery = PaymentQuery(batchQuery)
  batchQuery.skipSubs = writable(false)

  // sometimes we need just update data and not refreshing all related resources
  batchQuery.loadCurrentWithoutSubs = (id) => {
    batchQuery.skipSubs.set(true)
    batchQuery.loadActive(id)
  }
  // let super_init = batch.init
  /**
   * Overrrides
   */
  async function init() {
    if(batchQuery.isReady()) return
    await batchQuery.loadConfigs()

    //setup default sort
    batchQuery.sort.set({id:'asc'})

    // subscribe to batch current item store
    const unsub = batchQuery.activeModel.subscribe(async _batch => {
      // some time we need just update data and not refreshing all related resources
      if(get(batchQuery.skipSubs)) {
        batchQuery.skipSubs.set(false)
        return
      }
      if(isEmpty(_batch)) {
        paymentQuery.clear()
      } else {
        //clear page right away so nothing is shown
        paymentQuery.clear()
        paymentQuery.restrictSearch.set({batchId: _batch.id})
        await paymentQuery.query()
        await paymentQuery.updateTotals(_batch)
      }
    });

    batchQuery.unsubs.push(unsub)
    paymentQuery.init()
    batchQuery.setReady()
  }

  extend(batchQuery, {
    init,
    paymentQuery: paymentQuery,

    /**
    * gets batch, sets it to current and initalizes paymentApi
    */
    load(id){
      log("BatchQuery load", id)
      if(!id) throw new Error("load called without id")
      batchQuery.setLoading(true)
      batchQuery.activeModel.set({})
      //make sure we are initialized
      return batchQuery.init()
        .then(() => batchQuery.loadActive(id))
    },

    async rpc(name, data){
      paymentQuery.setLoading(true)
      const res = await batchQuery.dataApi.rpc(name, data)
      await batchQuery.loadActive()
      return res
    }
  })

  return batchQuery
}



