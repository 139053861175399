import {getLink} from '../linker'

const formatters = {
  // Show refnum link
  adjustLineRefLink: (cellVal, options, rowData) => {
    const ident = rowData.arTran?.id
    let href = getLink('arTran', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },

  adjustLineCustAccLink: (cellVal, options, rowData) => {
    const ident = rowData.arTran?.custAccount?.id
    let href = getLink('custAccount', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  }
}

export default formatters
