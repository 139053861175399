<script>
  import Logger from '@yakit/core/logger'; let log = Logger(false);
  import { Page, Navbar, List, ListItem, AccordionContent} from 'framework7-svelte';
  import {Icon} from '@yakit/ui/svelte'
  import routesHolder from '../routes/holder';
  import appCtx from '~/injector'
  import t from '@yakit/ui/i18n'
  import {opened, overlay} from './sidebarState'
  import './SidebarMenu.scss'

  let sidebarConfig = appCtx.get('appState').sidebarConfig
  let user = appCtx.get('appState').user
  $: log("**** sidebarConfig", $sidebarConfig)
  $: log("***** currentUser", $user)
  // $: console.log("sidebarConfig", $sidebarConfig)
  export let isDev = appCtx.get('isDev')

  $: nav = $sidebarConfig?.nav //$user?.isCustomer ? customerRoutes :$sidebarConfig?.nav

  function itemClick(){
    if($overlay) $opened = false
  }
  // let routes = routesHolder.getRoutes()

</script>

<Page class="page-sidebar">
  <Navbar id='sidebar-navbar'>
    <div class="sidebar-logo">
      <!-- <img class="logo" alt="Company Logo" src="assets/images/logos/9ci-rcm.svg"> -->
      <img class="logo" alt="Company Logo" src="assets/images/logos/RNDC_Logo.svg">
    </div>
    <!-- <NavTitle>
      <img class="logo" src="assets/images/logos/9ci-rcm.svg">
    </NavTitle> -->
  </Navbar>

  {#if $sidebarConfig?.nav}
  <div class="menu-lists">

    {#each Object.keys(nav) as k}
      {#if nav[k].route }
        <List menuList noHairlines>
          <ListItem title={$t(`${nav[k].i18n || k}.label`)} link={routesHolder.getPath(nav[k].route)} onClick={itemClick}>
            <Icon slot="media" icon={$t(`${nav[k].i18n || k}.icon`)} />
          </ListItem>
        </List>
      {:else}

      <List>
        <ListItem class="header" accordionItem accordionItemOpened={true} title={$t(`${nav[k].i18n || k}.label`)} >
          <AccordionContent>
            {#each nav[k].routes as child}
            <List menuList noHairlinesBetween>
              <ListItem title={$t(`${child.i18n || child.key}.label`)} link={routesHolder.getPath(child.key)} onClick={itemClick}>
                <Icon slot="media" icon={$t(`${child.i18n || child.key}.icon`)} />
              </ListItem>
            </List>
            {/each}
          </AccordionContent>
        </ListItem>
      </List>
      {/if}
    {/each}
 <!-- Jobs menu TODO: move to config  -->
    <List>
      <ListItem class="header" accordionItem accordionItemOpened={true} title="System" >
        <AccordionContent>
          <List menuList noHairlinesBetween>
            <ListItem title="Jobs" link="/rally/syncjobs" >
              <Icon slot="media" icon="settings_suggest" />
            </ListItem>
          </List>
        </AccordionContent>
      </ListItem>
    </List>
     <!-- Jobs menu end  -->
    {#if isDev && !$user?.isCustomer}
    <List>
      <ListItem class="header" accordionItem accordionItemOpened={true} title="Components" >
        <AccordionContent>
          <List menuList noHairlinesBetween>
            <ListItem title="Style Guide" link="/style-guide" >
              <Icon slot="media" icon="style" />
            </ListItem>
          </List>
          <List menuList noHairlinesBetween>
            <ListItem title="Formify" link="/examples/formify" >
              <Icon slot="media" icon="view_list" />
            </ListItem>
          </List>
          <List menuList noHairlinesBetween>
            <ListItem title="Data Tables" link="/examples/datatable" >
              <Icon slot="media" icon="view_list" />
            </ListItem>
          </List>
          <List menuList noHairlinesBetween>
            <ListItem title="Flex Table" link="/examples/flextable" >
              <Icon slot="media" icon="view_list" />
            </ListItem>
          </List>
        </AccordionContent>
      </ListItem>
    </List>
    {/if}

  </div>
  {/if}

</Page>
