<script>
  import { f7, Icon, Navbar, NavRight, Link, Popover, List, ListItem } from 'framework7-svelte';
  import {Avatar} from '@yakit/ui/svelte'
  import appCtx from '~/injector'
  const appState = appCtx.get('appState')

  let curUser = appState.user

  let colorTheme = 'light'
  function toggleTheme(){
    colorTheme = colorTheme === 'light' ? 'dark' : 'light'
    setLayoutTheme(colorTheme)
  }

  function setLayoutTheme(newTheme) {
    const htmlEl = f7.$('html');
    htmlEl.removeClass('dark light').addClass(`${newTheme}`);
  }

</script>

{#if $curUser?.username}
  <NavRight>
    <Link iconF7="sun_max_fill" onClick={toggleTheme}/>
    <Link popoverOpen=".popover-menu">
      <Avatar class="is-small" name={$curUser.name} />
    </Link>
  </NavRight>

  <Popover class="popover-menu">
    <List>
      <ListItem header="Current User" title={$curUser.name}
        footer={`@${$curUser.username}`} >
        <Avatar slot="media" class="is-small" name={$curUser.name} />
      </ListItem>
      <ListItem link="/user/preferences" popoverClose title="Preferences">
        <Icon slot="media" material="settings" />
      </ListItem>
      <ListItem link="#" popoverClose title="Log Out" onClick={appState.logout}>
        <Icon slot="media" material="logout" />
      </ListItem>
    </List>
  </Popover>
{:else}
  <NavRight>
    <Link>...</Link>
  </NavRight>
{/if}
