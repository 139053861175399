import type { Result } from "../results/Result"
import Logger from '@yakit/core/logger'; const log = Logger(false)
import growl from "./notyf"
import { isEmpty } from '@yakit/core/utils'
import type { ValidationError, ValidationProblem } from "../results/ValidationProblem";


async function handleError(er) {
    let errMsg = er
    //for cases when we are passing http error
    if(er.response){
      errMsg = await problemErrorFromResponse(er)
    } else { // for cases when we are passing resolved promise, like for rpc
      errMsg = buildProblemError(er)
      log("handleError", errMsg)
    }
    growl.error(errMsg.message, errMsg.name)
  }

async function problemErrorFromResponse(er) {
    let problem = await er.response.json()
    let messages = []
    log("handleError", problem)
    //const message = er?.response?.status === 500 ? 'Unexpected error' : null
    return buildProblemError(problem)
  }

  function getValidationDetails(err: Result): string[] {
    if(!isEmpty(err.errors)){
      return  err.errors!.map(er => 
         `${er.field}: ${er.message}`
      )
    }
    return []
  }

  function getProblemDetails(err: Result): string[] {
    return err.problems?.map(problem => problem.detail || '') 
  }
  
  function buildProblemError(problem: Result ):{name: string, message: string} {
    let details: string[] = problem?.detail ? [problem.detail] : []

    if(problem.errors){
      details = getValidationDetails(problem)
    }

    if(problem.problems){
      details = getProblemDetails(problem)
    }

    return {
      name: problem.title,
      message: formatDetails(details)
    }

  } 

  function formatDetails(details: string[]): string{
    return `<ul>${details.map(detail => `<li>${detail}</li>`)}</ul>`
  }

  export default {
    handleError, problemErrorFromResponse
  }