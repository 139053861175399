import RestDataService from '@yakit/core/api/RestDataService'

/**
 * PaymentApi creator
 *
 * @param {*} opts to pass to the RestDataService
 * @returns the RestDataService with added methods
 */
function PaymentApi(opts){

  const payApi = RestDataService({ ...opts, key: 'autocash/payment' })

  /**
   * "trans": [
   *   {"op": "remove", "ids": [123,124]},
   *   {"op": "add",    "ids": [221,223]},
   * ]
   * @param {*} param0
   */
  payApi.insertFromTran = async ({ add, remove, paymentId, insertDiscount }) => {
    let item = {id: paymentId, trans: [] }
    if(remove) item.trans.push({op:"remove", ids: remove})
    if(add) item.trans.push({op:"add", ids: add})
    return payApi.update(item)
  }

  /**
   * amount sum for batch
   */
  payApi.sumTotals = async (batch) => {
    const params = {
      projections: { amount:'sum' },
      q: { batchId: batch.id }
    }
    let searchParams = payApi.setupQueryParams(params)
    const res = await payApi.fetch({ searchParams }).json()
    const dta = res.data[0]
    const amount = parseFloat(dta['amount_sum'])
    return { amount }
  }

  /**
   * Makes the rpc calls to server
   */
  payApi.rpc = async (name, data) => {
    const res = await payApi.fetch({method: 'post', path: `rpc?op=${name}`, json: data}).json()
    // reload the payment and batch
    // we dont reload here and do it from component
    // await batchApi.reloadCurrent()
    // await payApi.reloadPage()
    return res
  }

  return payApi
}

export default PaymentApi
