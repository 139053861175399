import { makeFetcher } from './kyFetcher'
import { _get, extend, prune } from '../objectz';
import stringify from '../utils/stringify';
import { dataApiCommon, type Downloder, type RestDataApi } from './dataApi'

/**
 * A common wrapper around RESTful resource
 * @returns the build dataApi
 */
export const RestDataService = ({ key, ky, idProp = 'id', ...overrides }): RestDataApi => {
  //if ky is undefiend then it gets from factory
  let fetcher = makeFetcher({ ky, pathKey: key })

  let dapi: RestDataApi = {
    ...fetcher,
    ...dataApiCommon,
    idProp,
    key,
    path: key,
    ky: ky,

    async create(item) {
      const newItem = await dapi.post({ json: item })
      return newItem
    },

    async update(item) {
      const { [idProp]: id, ...rest } = item
      const newItem = await dapi.put({ path: id, json: rest })
      return newItem
    },

    /** Returns a promise to remove (DELETE) an item. */
    async remove(id) {
      await dapi.delete({ path: id })
      return true
    },

    async bulkUpdate(muItem) {
      const {ids, data} = muItem
      const values = ids?.map((id) => {return {...data, id}})
      const results = await dapi.fetch({path: 'bulk', method:'PUT', json: values }).json()
      return results
    },

    async fetchById(id) {
      const item = await dapi.fetch({ path: id }).json()
      // ds.stores.setItem(item)
      return item
    },

    async search(params) {
      let searchParams = dapi.setupQueryParams(params)
      const page = await dapi.fetch({ searchParams }).json()
      return page
    },

    async download(params) {
      let searchParams = dapi.setupQueryParams(params)
      const blob = await dapi.fetch({ searchParams }).blob()
      return blob
    },

    // prunes params and stringifies the q param if exists
    setupQueryParams(params){
      params = prune(params) //removes properties with null or undefined values
      //merge restrictSearch in if it there
      if(dapi.restrictSearch) {
        params.q = {...params.q, ...dapi.restrictSearch}
      }
      return dapi.stringifyQuery(params)
    },

    stringifyQuery(params){
      let { q, sort, projections } = params
      if(q) params.q = stringify(q)
      if(projections) params.projections = stringify(projections)
      //stringify sort and remove the quotes and brackets
      if(sort) params.sort = stringify(sort).replace(/{|}|"/g, '')
      return params
    },

    async picklist(params) {
      let cleanParams = dapi.setupQueryParams(params)
      const o = { path: 'picklist', searchParams: cleanParams }
      const data = await dapi.fetch(o).json()
      return data
    },

    async rpc(op, data) {
      const res = await dapi.fetch({method: 'post', path: `rpc?op=${op}`, json: data}).json()
      return res
    }
  }

  return extend(dapi, overrides)

}

export default RestDataService
