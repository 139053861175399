<script>
  import { Page, BlockTitle, Block, Navbar } from 'framework7-svelte';
  import AppBarLarge from '../nav/AppNavBarLarge.svelte'

</script>

<Page class="page-about">
  <AppBarLarge title="About"/>

  <BlockTitle medium>Welcome to 9ci</BlockTitle>
  <Block strong>
    <p>
      Lorem
    </p>
    <p>
      Lorem
    </p>
    <p>
      Lorem
    </p>
    <p>
      Lorem
    </p>
  </Block>
</Page>
