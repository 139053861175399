import RestDataService from '@yakit/core/api/RestDataService'

/**
 * Customer Rest Api additions
 */
export default function(opts){

  const attachmentAPi = RestDataService({ ...opts, key: 'rally/attachment' })

  attachmentAPi.upload = async (data) => {
    return {}
  }

  return attachmentAPi
}

