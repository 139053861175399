import { get, writable } from 'svelte/store';
import {isEmpty} from '@yakit/core/is'
import { makeQueryStore } from '@yakit/core/resource/QueryStore'
import { extend } from '@yakit/core/objectz';
import {PayDetailTranApi, PaymentDetailApi} from '~/api/autocash'

/**
 * Customer Rest Api additions
 */
function PayDetailQuery({ paymentQuery }){

  const detailApi = PaymentDetailApi({ky: paymentQuery.dataApi.ky})
  const payDetailQuery = makeQueryStore({dataApi: detailApi})
  const currentPayment = paymentQuery.activeModel
  const totals = writable({})
  const getTotals = () => { get(totals) }

  function checkPosted() {
    if (currentPayment.get().arPostedDate) {
     throw new Error('Posted payment cannot be changed')
    }
  }
  //new delete fn to check posted before call
  payDetailQuery.delete = async () => {
    if (currentPayment.get().arPostedDate) throw new Error('Posted payments cannot be changed')
    const paymentId = currentPayment.get().id
    const paymentDetailIds = [...get(payDetailQuery.selectedIds)]
    const result = await detailApi.deleteForPayment(paymentId, paymentDetailIds)
    await paymentQuery?.payDetailTranQuery?.reload()
    await reload()
    return result
  }

  const updateTotals = async (_payment) => {
    //we update based on the _payment so we can get the current amount and calc the out of balance
    const pa = _payment || currentPayment.get()
    if(isEmpty(pa)) return
    const sumData = await detailApi.sumTotals(pa)
    totals.set(sumData)
  }

  const reload = async () => {
    await payDetailQuery.query()
    await updateTotals()
  }

  extend(payDetailQuery, {
    // current total for the payment. Will get updated when paymentApi.currentPayment changes
    // or when triggered from a payment edit or delete
    totals,
    updateTotals,
    /** gets the total values */
    getTotals(){
      return get(totals)
    },
    reload,
    /** Calls the duplicate/copy rpc on server */
    async duplicate(ids) {
      const results = await detailApi.post({path: 'copy', json: {ids}})
      await payDetailQuery.reload()
      return results
    },
    bulkCreate(data) {
      return detailApi.bulkCreate(data)
    }
  })

  return payDetailQuery
}

export default PayDetailQuery
