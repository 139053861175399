import Logger from '../logger'; let log = Logger(false);
import { get, writable, derived } from 'svelte/store';
import {getInjector} from '../injector'
import { _get, extend} from '../objectz';

/** @typedef {import('svelte/store').Writable<{}>} Writable */

export const createConfigStore = ({dataApi}) => {

  let apiPath = dataApi.path
  let ky = dataApi.ky

  /*
  isLoading or status === 'loading' - The query has no data and is currently fetching
  isError or status === 'error' - The query encountered an error
  isSuccess or status === 'success' - The query was successful and data is available
  isIdle or status === 'idle' - The query is currently disabled (you'll learn more about this in a bit)
  */
  const storeValues = {
    isSuccess: false, //config is loaded
    data: undefined,
    meta: undefined
  }

  //the store sub that is returned
  const store = writable(storeValues)

  const obj = {
    ky,
    store,
    dataApi,
    apiPath
  }

  return extend(obj, {
    //subscribe and set implement store contract so return can be used as store to check state $resource.isReady for example
    subscribe: store.subscribe,
    set: store.set,
    update: store.update,

    async init() {
      if(storeValues.isSuccess) return
      await obj.loadConfigs()
      storeValues.isSuccess = true
      store.set(storeValues)
      return obj
    },

    /** returns an id from the apiKey */
    ident(){
      return apiPath.replace('/', '_')
    },

    /** lazy load the app config */
    async getConfigs(){
      if(!storeValues.data) { await obj.loadConfigs() }
      return storeValues.data
    },
    /** load the app config */
    async loadConfigs(){
      const appConfigApi = getInjector().get('appConfigApi')
      storeValues.data = await appConfigApi.getConfig(apiPath)
      store.update(_state => ({...storeValues, isSuccess:true})  )
    },

    async loadMeta(){
      const ky = getInjector().get('ky')
      storeValues.meta = await ky.get('appConfig/meta/'+apiPath).json()
      store.update(_state => ({..._state, isSuccess:true}) )
    },

    async getMeta(){
      if(!storeValues.meta) { await obj.loadMeta() }
      return storeValues.meta
    },
    //normally use the $store.isSuccess but if mixing custom resource can use this as a shortcut check
    isSuccess(){
      return storeValues.isSuccess
    },
    //use when mixing custom resources
    setSuccess(){
      store.update(_state => ({..._state, isSuccess:true}))
    },

  }) //end mix
}

export default createConfigStore

