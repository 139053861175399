/**
 * Creates and editable store model that a form can be bound to.
 * - validates against yup
 * - tracks errors and changes
 * - track if its being modified
 *
 * based on https://github.com/tjinauyeung/svelte-forms-lib so we can make changes
 * and tie it in better with the f7 way of doing things as well as our dataApi stores.
 * Also makes it a general purpose "editor" and not just for using forms.
 */
import Logger from '../logger'; let log = Logger(false);
import {derived, writable, get} from 'svelte/store';
import { cloneDeep, deepValues, deepUpdate, deepDiff, _get, _set } from '../objectz';
import { updateValue } from '../stores/storeTools';
import {isNil, isEqual, isEmpty} from '../utils/is';

const NO_ERROR = '';
const IS_TOUCHED = true;

function isCheckbox(element) {
  return element.getAttribute && element.getAttribute('type') === 'checkbox';
}

function isFileInput(element) {
  return element.getAttribute && element.getAttribute('type') === 'file';
}

function resolveValue(element) {
  if (isFileInput(element)) {
    return element.files;
  } else if (isCheckbox(element)) {
    return element.checked;
  } else {
    return element.value;
  }
}

export const createModelEditor = (config) => {
  let modelData = config.modelData || {};

  const validationSchema = config.validationSchema;
  const validateFunction = config.validate;
  const onSubmit = config.onSubmit;
  //onSumbit pass full model  or only the changes, default is changes
  const submitDiff = config.submitDiff ?? true

  function cloneModelData(){
    return cloneDeep(modelData)
  }

  /**
   * Uses the schema and builds and empty error model object.
   */
  function initErrorsModel(){
    let errmodel = validationSchema
        ? getErrorsFromSchema(modelData, validationSchema.fields)
        : {}
    log("getErrorsModel", errmodel)
    return errmodel
  }

  /**
   * Uses the modelData and builds an object with false for each field.
   */
  function initTouchedModel(){
    let touchedModel = deepUpdate(modelData, !IS_TOUCHED)
    log("initTouchedModel", touchedModel)
    return touchedModel
  }

  // the data item or row being edited or created. We call it model to avoid confusion and try to make it more obvious.
  const model = writable(cloneModelData())
  const errors = writable(initErrorsModel())
  const touched = writable(initTouchedModel())

  const isSubmitting = writable(false)
  const isValidating = writable(false)
  const isModifying = writable(false)

  //stores used for events
  const beforeSubmit = writable({})
  const afterSubmit = writable({})

  const isValid = derived(errors, ($errors) => {
    const noErrors = deepValues($errors)
      .every((field) => field === NO_ERROR);
    return noErrors;
  })

  const modified = derived(model, ($model) => {
    //starts with false on all the fields, deep nested works too
    const object = deepUpdate($model, false)

    for (let key in $model) {
      object[key] = !isEqual($model[key], modelData[key])
    }
    return object
  });

  const isModified = derived([modified, isModifying], ([$modified, $isModifying]) => {
    return deepValues($modified).includes(true) || $isModifying;
  });

  const isDisableSave = derived([isModified, isSubmitting],
    ([$isModified, $isSubmitting]) => {
      //if its not modified and not modifying
      let val = !($isModified) || $isSubmitting
      return val
  });

  const state = derived([ model, errors, touched, modified, isValid, isValidating, isSubmitting, isModified ],
      ([ $model, $errors, $touched, $modified, $isValid, $isValidating, $isSubmitting, $isModified, ]) => ({
      model: $model,
      errors: $errors,
      touched: $touched,
      modified: $modified,
      isValid: $isValid,
      isSubmitting: $isSubmitting,
      isValidating: $isValidating,
      isModified: $isModified,
    })
  )

  function validateField(field) {
     let $model = get(model) //.then((values) => validateFieldValue(field, values[field]))
     return validateFieldValue(field, $model[field])
  }

  function validateFieldValue(field, value) {
    updateTouched(field, true);

    if (validationSchema) {
      isValidating.set(true);

      return validationSchema
        .validateAt(field, get(model))
        .then(() => updateValue(errors, field, ''))
        .catch((error) => updateValue(errors, field, error.message))
        .finally(() => {
          isValidating.set(false);
        });
    }

    if (validateFunction) {
      isValidating.set(true);
      return Promise.resolve()
        .then(() => validateFunction({[field]: value}))
        .then((errs) =>
          updateValue(errors, field, !isNil(errs) ? errs[field] : ''),
        )
        .finally(() => {
          isValidating.set(false);
        });
    }

    return Promise.resolve();
  }

  function updateValidateField(field, value) {
    updateField(field, value);
    return validateFieldValue(field, value);
  }

  function handleChange(event) {
    const element = event.target;
    const field = element.name || element.id;
    const value = resolveValue(element);

    return updateValidateField(field, value);
  }

  async function handleSubmit(event) {
    log("calling handleSubmit")
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    isSubmitting.set(true);
    //get changes from model.
    const values = get(model)


    if (typeof validateFunction === 'function') {
      isValidating.set(true)

      const error = await validateFunction(values)
      if (isNil(error) || deepValues(error).length === 0) {
        clearErrorsAndSubmit(values)
      } else {
        errors.set(error)
        isSubmitting.set(false)
      }
      isValidating.set(false)
    }
    else if (validationSchema) {
      isValidating.set(true)
      try {
        let valRes = await validationSchema.validate(values, {abortEarly: false})
        clearErrorsAndSubmit(values)
      } catch (yupErrors) {
        // console.log("has yupErrors", yupErrors.message)
        if (yupErrors && yupErrors.inner) {
          const updatedErrors = initErrorsModel();
          yupErrors.inner.map((error) =>
            _set(updatedErrors, error.path, error.message),
          )
          errors.set(updatedErrors)
          isSubmitting.set(false)
        }
      } finally {
        isValidating.set(false)
      }
    }
    else {
      clearErrorsAndSubmit(values);
    }
  }

  function handleReset() {
    model.set(cloneModelData());
    errors.set(initErrorsModel());
    touched.set(initTouchedModel());
    isModifying.set(false);
    // logInfo()
  }

  function logInfo() {
    log("form", get(model))
    log("errors", get(errors))
    log("touched", get(touched))
    log("modified", get(modified))
    log("isModifying", get(isModifying))
  }

  async function clearErrorsAndSubmit(values) {
    errors.set(initErrorsModel())
    log("calling on submit with values",values)
    logInfo()
    // diff changes
    let changes = submitDiff ? deepDiff(modelData, values) : values

    await onSubmit(changes, modelData, errors)
    isSubmitting.set(false)
    isModifying.set(false)
  }

  /**
   * Handler to imperatively update the value of a model field
   */
  function updateField(field, value) {
    updateValue(model, field, value);
  }

  /**
   * Handler to imperatively update the touched value of a model field
   */
  function updateTouched(field, value) {
    updateValue(touched, field, value);
  }

  /**
   * Update the initial values and reset form. Used to dynamically display new form values
   */
  function updateModel(newValues) {
    modelData = newValues;

    handleReset();
  }

  return {
    model,
    errors,
    touched,
    modified,
    isValid,
    isSubmitting,
    isValidating,
    isModified,
    isDisableSave,
    handleChange,
    handleSubmit,
    handleReset,
    updateField,
    updateValidateField,
    updateTouched,
    validateField,
    updateModel,
    isModifying,
    state,
  }
}

// TODO: refactor this so as not to rely directly on yup's API
// This should use dependency injection, with a default callback which may assume
// yup as the validation schema
function getErrorsFromSchema(modelData, schema, errors = {}) {
  for (const key in schema) {
    switch (true) {
      case schema[key].type === 'object' && !isEmpty(schema[key].fields): {
        errors[key] = getErrorsFromSchema(
          modelData[key],
          schema[key].fields,
          {...errors[key]},
        );
        break;
      }

      case schema[key].type === 'array': {
        const values =
          modelData && modelData[key] ? modelData[key] : [];
        errors[key] = values.map((value) => {
          const innerError = getErrorsFromSchema(
            value,
            schema[key].innerType.fields,
            {...errors[key]},
          );

          return Object.keys(innerError).length > 0 ? innerError : '';
        });
        break;
      }

      default: {
        errors[key] = '';
      }
    }
  }

  return errors;
}
