import { findIndexById, findById } from '../utils/finders'

import type { Fetcher, KyInstance } from './kyFetcher'

/**
 * Facade around ky rest calls representing an api endpoint to do CRUD and Query operation.
 */
export interface DataApi {
  // [x: string]: any;
  /** The default id property name. defaults to "id" */
  idProp: string;
  restrictSearch: object;
  key: string;
  path: string;

  /**
   * adds searchParams, which are the query params ( the part after the ? )
   * and then calls the get. if the params has a q property and its a string then
   * @param {*} params
   */
  search(params?: QueryParams): Promise<any>;

  /**
   * same as search but returns blob instead of json
   * @param params 
   */
   download?(params?: QueryParams): Promise<Blob>;

  /**

   * Fuzzy text search.
   * @param {string} searchKey
   * @returns the filtered items
   */
  // qSearch(text: string): Promise;

  picklist(params: object): Promise<any>;

  /**
   * Returns a promise for the json with the given identifier
   */
  fetchById(id: any): Promise<any>;

  /**
   * gets the item in the current data list for the id.
   * If the passed in data is a store then it will do a get(list) to unwrap its data first
   */
  findById(data: any, id: any): object | boolean;

  /**
   * searches array for id key match and returns it index in the array
   */
  findIndexById(list: any[], id: any ): number;

  save(item: object): Promise<any>;

  create(item: object): Promise<any>;

  /** Returns a promise to save (PUT) an existing item. */
  update(item): Promise<any>;

  /**
  * Returns a promise to remove (DELETE) an item.
  */
  remove(id): Promise<any>;

  /**
   * Bulk updates items
   */
  bulkUpdate({ids, data}): Promise<any>;

  /**
   * RPC operation
   * 
   * @param name 
   * @param data 
   */
  rpc?(op: string, data: object): Promise<any>;
}

/**
 * Facade around ky rest calls representing an api endpoint to do CRUD and Query operation.
 */
export interface RestDataApi extends Fetcher, DataApi {
  // [x: string]: unknown;
  ky: KyInstance;
  setupQueryParams(params: QueryParams): any;
  stringifyQuery(params: QueryParams): any;
}

export interface QueryParams {
  q?: any;
  sort?: any;
  projections?: any;
  qSearch?: any;
  order?: any; //FIXME remove this
  page?: any;
  max?: any;
}

export const dataApiCommon = {
  idProp: 'id',
  restrictSearch: null,

  findById(data, id){
    return findById(data, id, this.idProp)
  },

  findIndexById(list, id){
    return findIndexById({ list, id , idProp: this.idProp })
  },

  save(item) {
    return item[this.idProp] ? this.update(item) : this.create(item)
  },

}
